<template>
    <v-container fluid class="pt-0 d-flex flex-column full-height">
        <v-row class="flex-grow-0">
            <v-col>
                <h1 class="text--xl font-weight-medium">{{ title }}</h1>
            </v-col>
            <template v-if="$slots.actions">
                <v-spacer />
                <v-col cols="12" md="auto">
                    <slot name="actions" />
                </v-col>
            </template>
        </v-row>
        <slot name="filters" />
        <div class="d-contents"><slot name="main"></slot></div>
    </v-container>
</template>

<script>
export default {
    name: 'Content',
    props: {
        title: {
            type: String,
            required: true
        }
    }
};
</script>
